<template>
  <v-card class="px-3 px-md-6 py-6 custom-scroll">
    <v-row class="mb-5">
      <v-col cols="12" md="auto">
        <v-select
          v-model="filters.businessId"
          :items="businesses"
          :menu-props="{ contentClass: 'theme-custom-input-dropdown-menu' }"
          :placeholder="
            $t(
              'panel.transactionsPage.depositTransactions.depositTransactionsTable.allBusinesses'
            )
          "
          append-icon="$arrowDown"
          class="theme-custom-input custom-dropdown-width cashIns-filter"
          dense
          filled
          height="40"
          hide-details
          item-text="businessName"
          item-value="id"
          @change="setFilters"
        >
          <template v-slot:selection="{ item }">
            <span
              class="font-regular-12 gray12--text d-inline-block text-truncate"
            >
              {{ item.businessName }}
            </span>
          </template>

          <template v-slot:item="{ active, item, attrs, on }">
            <v-list v-on="on" #default>
              <v-list-item>
                <span class="font-regular-12 gray12--text">{{
                  item.businessName
                }}</span>
              </v-list-item>
            </v-list>
            <!--            <v-divider v-if="item.id === ''" />-->
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="auto">
        <v-select
          v-model="filters.gatewayTypes"
          :items="gatewayTypes"
          :menu-props="{ contentClass: 'theme-custom-input-dropdown-menu' }"
          :placeholder="
            $t(
              'panel.transactionsPage.depositTransactions.depositTransactionsTable.allTypes'
            )
          "
          append-icon="$arrowDown"
          class="theme-custom-input custom-dropdown-width cashIns-filter"
          dense
          filled
          height="40"
          hide-details
          item-text="gatewayName"
          item-value="gatewayType"
          @change="setFilters"
        >
          <template v-slot:selection="{ item }">
            <span
              class="font-regular-12 gray12--text d-inline-block text-truncate"
              >{{ item.gatewayName }}</span
            >
          </template>

          <template v-slot:item="{ active, item, attrs, on }">
            <v-list v-on="on" #default>
              <v-list-item>
                <span class="font-regular-12 gray12--text">{{
                  item.gatewayName
                }}</span>
              </v-list-item>
            </v-list>
            <!--            <v-divider v-if="item.gatewayType === ''" />-->
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-data-table
      :class="{ 'no-data-wrapper-height': !tableData.length }"
      :headers="headers"
      :items="tableData"
      :loading="loading"
      class="custom-table-height cashIns-table"
      fixed-header
      hide-default-footer
    >
      <template v-slot:header.localFiatCurrency="{ header }">
        <span>{{ header.text }}</span>
        <v-tooltip
          v-model="showCryptoTooltip"
          class="transactions-table-tooltip"
          color="white"
          content-class="transactions-table-tooltip"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="ms-1"
              color="transparent"
              size="16"
              >$info
            </v-icon>
          </template>
          <span class="font-regular-12 gray8--text">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionsTable.cryptoAmountTooltipDesc"
              )
            }}
          </span>
        </v-tooltip>
      </template>

      <!--      <template v-slot:header.cryptoAmount="{ header }">-->
      <!--        <span>{{ header.text }}</span>-->
      <!--        <v-tooltip-->
      <!--          v-model="showUsdTooltip"-->
      <!--          top-->
      <!--          color="white"-->
      <!--          content-class="transactions-table-tooltip"-->
      <!--          class="transactions-table-tooltip"-->
      <!--        >-->
      <!--          <template v-slot:activator="{ on, attrs }">-->
      <!--            <v-icon-->
      <!--              size="16"-->
      <!--              color="transparent"-->
      <!--              class="ms-1"-->
      <!--              v-bind="attrs"-->
      <!--              v-on="on"-->
      <!--              >$info-->
      <!--            </v-icon>-->
      <!--          </template>-->
      <!--          <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--            {{-->
      <!--              $t(-->
      <!--                "panel.transactionsPage.depositTransactions.depositTransactionsTable.usdValueTooltipDesc"-->
      <!--              )-->
      <!--            }}-->
      <!--          </span>-->
      <!--        </v-tooltip>-->
      <!--      </template>-->

      <!--/////////////////////////////////////////////////////////////////////////////////-->

      <!--            <template v-slot:header="{ props: { headers } }">-->
      <!--              <thead>-->
      <!--                <tr>-->
      <!--                  <th v-for="item in headers" :key="item.value">-->
      <!--                    <span class="font-regular-12 gray9&#45;&#45;text">{{ item.text }}</span>-->

      <!--                    <v-tooltip-->
      <!--                      v-if="item.value === 'cryptoAmount'"-->
      <!--                      v-model="showCryptoTooltip"-->
      <!--                      top-->
      <!--                      color="white"-->
      <!--                      content-class="transactions-table-tooltip"-->
      <!--                      class="transactions-table-tooltip"-->
      <!--                    >-->
      <!--                      <template v-slot:activator="{ on, attrs }">-->
      <!--                    <v-icon-->
      <!--                      size="16"-->
      <!--                      color="transparent"-->
      <!--                      class="ms-1"-->
      <!--                      v-bind="attrs"-->
      <!--                      v-on="on"-->
      <!--                      >$info-->
      <!--                    </v-icon>-->
      <!--                      </template>-->
      <!--                      <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--                        {{-->
      <!--                          $t(-->
      <!--                            "panel.transactionsPage.depositTransactions.depositTransactionsTable.cryptoAmountTooltipDesc"-->
      <!--                          )-->
      <!--                        }}-->
      <!--                      </span>-->
      <!--                    </v-tooltip>-->

      <!--                    <v-tooltip-->
      <!--                      v-if="item.value === 'localFiatCurrency'"-->
      <!--                      v-model="showUsdTooltip"-->
      <!--                      top-->
      <!--                      color="white"-->
      <!--                      content-class="transactions-table-tooltip"-->
      <!--                      class="transactions-table-tooltip"-->
      <!--                    >-->
      <!--                      <template v-slot:activator="{ on, attrs }">-->
      <!--                        <v-icon-->
      <!--                          size="16"-->
      <!--                          color="transparent"-->
      <!--                          class="ms-1"-->
      <!--                          v-bind="attrs"-->
      <!--                          v-on="on"-->
      <!--                          >$info-->
      <!--                        </v-icon>-->
      <!--                      </template>-->
      <!--                      <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--                        {{-->
      <!--                          $t(-->
      <!--                            "panel.transactionsPage.depositTransactions.depositTransactionsTable.usdValueTooltipDesc"-->
      <!--                          )-->
      <!--                        }}-->
      <!--                      </span>-->
      <!--                    </v-tooltip>-->
      <!--                  </th>-->
      <!--                </tr>-->
      <!--              </thead>-->
      <!--            </template>-->

      <template v-slot:item.transactionId="{ item }">
        <span class="font-regular-12">{{ item.transactionId }}</span>
      </template>

      <template v-slot:item.transactionTime="{ item }">
        <span class="font-regular-12 text-no-wrap">{{
          item.transactionTime | $renderDate
        }}</span>
      </template>

      <template v-slot:item.businessName="{ item }">
        <div class="font-regular-12 name-truncate">{{ item.businessName }}</div>
      </template>

      <template v-slot:item.gatewayType="{ item }">
        <span class="font-regular-12">{{ item.gatewayType }}</span>
      </template>

      <template v-slot:item.cryptoAmount="{ item }">
        <div class="d-flex">
          <span class="font-regular-12 me-1">{{
            item.cryptoAmountString
          }}</span>
          <span class="font-regular-12">{{ item.cryptoName }}</span>
        </div>
      </template>

      <template v-slot:item.localFiatCurrency="{ item }">
        <span class="font-regular-12">${{ item.localFiatCurrencyString }}</span>
      </template>

      <template v-slot:item.transactionStatus="{ item }">
        <v-chip
          :color="item.colors.bg_color"
          :text-color="item.colors.text_color"
          small
        >
          <span class="font-regular-10">{{ item.transactionStatusText }}</span>
        </v-chip>
      </template>

      <template v-slot:no-results>
        <span class="gray7--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionsTable.noResult"
            )
          }}
        </span>
      </template>

      <template v-slot:no-data class="d-flex align-center justify-center">
        <v-icon class="mb-3 d-block mx-auto" color="gray5" size="62"
          >$itemInPage
        </v-icon>

        <span class="gray7--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionsTable.noData"
            )
          }}
        </span>
      </template>

      <template v-slot:item.action="{ item }">
        <router-link
          :to="`/panel/transactions/deposit-details/${item.transactionId}`"
          class="px-2 text-decoration-none"
        >
          <span class="font-semiBold-12">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionsTable.details"
              )
            }}
          </span>
        </router-link>

        <template v-if="item.manualAble">
          <v-btn
            v-if="item.transactionStatus !== 'EXPIRE' && !item.accepted"
            :loading="acceptBtnLoading === item.transactionId"
            :ripple="false"
            class="px-2"
            color="primary"
            text
            @click="openAcceptModal(item.transactionId)"
          >
            <span class="font-semiBold-12">
              {{
                $t(
                  "panel.transactionsPage.depositTransactions.depositTransactionsTable.accept"
                )
              }}
            </span>
          </v-btn>

          <v-btn
            v-if="item.transactionStatus !== 'EXPIRE' && !item.refunded"
            :loading="refundBtnLoading === item.transactionId"
            :ripple="false"
            class="px-2"
            color="primary"
            text
            @click="openRefundModal(item.transactionId)"
          >
            <span class="font-semiBold-12">
              {{
                $t(
                  "panel.transactionsPage.depositTransactions.depositTransactionsTable.refund"
                )
              }}
            </span>
          </v-btn>
        </template>

        <v-btn
          v-if="item.transactionStatus !== 'Overdue' && item.retryAble"
          :loading="checkBtnLoading === item.transactionId"
          :ripple="false"
          class="px-2"
          color="primary"
          icon
          @click="checkStatus(item)"
        >
          <v-icon clr="primary" size="20" strokeWidth="0.5">$reload</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer>
        <div v-if="totalPages > 0" class="d-flex justify-end mt-5">
          <v-pagination
            v-model="filters.page"
            :length="totalPages"
            :next-icon="nextIcon"
            :prev-icon="prevIcon"
            :total-visible="7"
            color="gray2"
            @input="updateQuery"
          >
          </v-pagination>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="refundModal" width="468">
      <v-btn
        class="ml-auto d-block"
        color="transparent"
        icon
        @click="closeRefundModal"
      >
        <v-icon size="24">$close</v-icon>
      </v-btn>

      <component
        :is="refundComponent"
        :trx-details="trxDetails"
        @updateRecord="updateTrx"
        @close="closeRefundModal"
        :key="trxDetails.fee + trxDetails.receivedAmount"
      />
    </v-dialog>

    <v-dialog v-model="acceptModal" width="468">
      <v-btn
        class="ml-auto d-block"
        color="transparent"
        icon
        @click="closeAcceptModal"
      >
        <v-icon size="24">$close</v-icon>
      </v-btn>

      <accept-modal
        :data="trxDetails"
        @updateRecord="updateTrx"
        @close="closeAcceptModal"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { MerchantService, ReportService, AccountService } from "@/services";
import renderDate from "@/mixins/renderDate";

const RefundModal = () =>
  import(/* webpackChunkName: "refund-modal" */ "../RefundModal");
const RefundWarningModal = () =>
  import(/* webpackChunkName: "refund-modal" */ "../RefundWarningModal");
const AcceptModal = () =>
  import(/* webpackChunkName: "accept-modal" */ "../AcceptModal");

export default {
  name: "deposit-transactions-table",
  mixins: [renderDate],
  components: {
    RefundModal,
    RefundWarningModal,
    AcceptModal,
  },
  props: [],
  data() {
    return {
      nextIcon: "Next >",
      prevIcon: "< Back",
      filters: {
        businessId: "",
        gatewayTypes: "",
        page: 1,
        size: 10,
      },
      businesses: [],
      gatewayTypes: [],
      loading: false,
      showCryptoTooltip: false,
      showUsdTooltip: false,
      refundModal: false,
      trxDetails: {},
      refundBtnLoading: null,
      acceptBtnLoading: null,
      checkBtnLoading: null,
      canRefund: false,
      acceptModal: false,
      headers: [
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.transactionId"
          ),
          align: "start",
          sortable: false,
          value: "transactionId",
        },
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.dateTime"
          ),
          align: "start",
          sortable: false,
          value: "transactionTime",
        },
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.businessName"
          ),
          align: "start",
          sortable: false,
          value: "businessName",
          width: 116,
        },
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.type"
          ),
          align: "start",
          sortable: false,
          value: "gatewayType",
        },
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.cryptoAmount"
          ),
          align: "start",
          sortable: false,
          value: "cryptoAmount",
        },
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.usdValue"
          ),
          align: "start",
          sortable: false,
          value: "localFiatCurrency",
        },
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.status"
          ),
          align: "start",
          sortable: false,
          value: "transactionStatus",
        },
        {
          text: this.$t(
            "panel.transactionsPage.depositTransactions.depositTransactionsTable.action"
          ),
          align: "start",
          sortable: false,
          value: "action",
        },
      ],
      tableData: [],
      totalPages: 0,
    };
  },
  computed: {
    refundComponent() {
      if (this.canRefund) return "RefundModal";
      else return "RefundWarningModal";
    },
  },
  mounted() {
    this.setFiltersFromQuery();
    this.getBusinessNames();
    this.getGatewayTypes();
    this.updateQuery();
  },
  methods: {
    setFiltersFromQuery() {
      Object.entries(this.$route.query).forEach(([key, val]) => {
        if (key === "page" || key === "size" || key === "businessId") {
          this.filters[key] = +val;
        } else this.filters[key] = val;
      });
    },
    updateQuery() {
      const queryParams = {};
      Object.entries(this.filters).forEach(([key, val]) => {
        if (val && val !== "All Types") {
          queryParams[key] = val;
        }
      });
      this.$router.replace({ query: { ...queryParams } }, () => {});
      this.getCashIns(queryParams);
    },
    setFilters() {
      this.filters.page = 1;
      this.updateQuery();
    },
    getCashIns(filters) {
      this.loading = true;
      ReportService.getDepositTransactions(filters)
        .then((res) => {
          this.tableData = res.data.data.content;
          this.totalPages = res.data.data.totalPages;
          if (this.tableData.length) {
            this.tableData.map((elem) => {
              elem.colors = this.getStatusColors(elem.transactionStatus);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBusinessNames() {
      MerchantService.getMerchantNames().then((res) => {
        this.businesses = [
          {
            id: "",
            businessName: this.$t(
              "panel.transactionsPage.depositTransactions.depositTransactionsTable.allBusinesses"
            ),
          },
          ...res.data.data.content.map((item) => {
            return { id: item.id, businessName: item.businessName };
          }),
        ];
      });
    },
    getGatewayTypes() {
      MerchantService.getGatewayTypes().then((res) => {
        this.gatewayTypes = [
          {
            gatewayName: this.$t(
              "panel.transactionsPage.depositTransactions.depositTransactionsTable.allTypes"
            ),
            gatewayType: "",
          },
          ...res.data.data.map((item) => {
            return { gatewayName: item, gatewayType: item };
          }),
        ];
      });
    },
    getStatusColors(status) {
      const data = this.$t("panel.stateStatuses").find(
        (elem) => elem.status === status
      );
      if (data) return data;
      else
        return this.$t("panel.stateStatuses").find(
          (elem) => elem.status === "OTHER"
        );
    },
    openRefundModal(trxId) {
      this.refundBtnLoading = trxId;
      this.checkTrx(trxId, "REFUND")
        .then(() => {
          this.canRefund = this.trxDetails.amount - this.trxDetails.fee > 0;
          this.trxDetails.trxId = trxId;
          this.refundModal = true;
        })
        .finally(() => {
          this.refundBtnLoading = null;
        });
    },
    closeRefundModal() {
      this.refundModal = false;
    },
    openAcceptModal(trxId) {
      this.acceptBtnLoading = trxId;
      this.checkTrx(trxId, "ACCEPT")
        .then(() => {
          this.trxDetails.trxId = trxId;
          this.acceptModal = true;
        })
        .finally(() => {
          this.acceptBtnLoading = null;
        });
    },
    closeAcceptModal() {
      this.acceptModal = false;
    },
    checkTrx(trxId, transactionType) {
      return AccountService.checkTransaction({
        checkTransactionType: transactionType,
        transactionId: trxId,
      }).then((res) => {
        this.trxDetails = res.data;
      });
    },
    getTrxDetails(trxId) {
      return ReportService.getDepositTrxById(trxId);
    },
    checkStatus(item) {
      this.checkBtnLoading = item.transactionId;
      AccountService.checkNetworkStatus({
        transactionId: item.transactionId,
      })
        .then(() => {
          this.updateTrx(item.transactionId);
        })
        .finally(() => {
          this.checkBtnLoading = null;
        });
    },
    updateTrx(trxId) {
      this.getTrxDetails(trxId).then((res) => {
        const updatedRecord = res.data.data;
        this.tableData.map((elem) => {
          if (elem.transactionId === trxId) {
            elem.transactionStatus = updatedRecord.transactionStatus;
            elem.colors = this.getStatusColors(elem.transactionStatus);
            elem.transactionStatusText = updatedRecord.transactionStatusText;
            elem.cryptoAmountString = updatedRecord.cryptoAmountString;
            elem.localFiatCurrencyString =
              updatedRecord.localFiatCurrencyString;
            elem.manualAble = updatedRecord.manualAble;
            elem.accepted = updatedRecord.accepted;
            elem.refunded = updatedRecord.refunded;
            elem.retryAble = updatedRecord.retryAble;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "DepositTransactionsTable";
</style>
