export default {
  filters: {
    $renderDate(time) {
      if (/\d/g.test(time)) {
        const d = new Date();
        d.setTime(time);
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        let hour = d.getHours();
        if (hour <= 9) {
          hour = "0" + hour;
        }
        let minutes = d.getMinutes();
        if (minutes <= 9) {
          minutes = "0" + minutes;
        }
        return year + "/" + month + "/" + day + "-" + hour + ":" + minutes;
        // return d.toLocaleString(undefined, {
        //   dateStyle: "short",
        //   timeStyle: "short",
        //   hour12: false,
        // });
      } else {
        return time;
      }
    },
  },
};
